import axios from "../../axios";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";

import CustomToast from "../../reusable/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import Select from "react-select";

const AddEmailInstance = ({ show, handleClose, refetchEmailSentData }) => {
  const [state, setState] = useState("");
  const [dispensaries, setDispensaries] = useState([]);
  const [selectOption, setSelectOption] = useState("");
  const [error, setError] = useState("");

  //toast states
  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);
  //loading
  const loading = useSelector((state) => state.loading.loading);

  //dispatch
  const dispatch = useDispatch();

  //Functions

  const getStates = async () => {
    const res = await axios(`/states`);
    return res.data.data.states;
  };
  const {
    data: states = [],
    isLoading: statesLoading,
    error: statesError,
  } = useQuery({
    queryKey: ["states-list"],
    queryFn: getStates,
  });
  const handleSave = async () => {
    if (!state || !dispensaries) {
      setError("Please add State and dispensaries");
      return;
    }
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "post",
        url: "/send/email/dispensary",
        data: { ids: dispensaries.map((disp) => disp.value) },
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchEmailSentData();
      handleClose();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  const getDispensaries = async ({ queryKey }) => {
    const [, state] = queryKey;
    const res = await axios(`/get/dispensary?state=${state.value}`);
    return res.data.data.dispensaries;
  };
  const {
    data: stateDispensaries = [],
    isLoading: stateDispensariesLoading,
    error: stateDispensariesError,
  } = useQuery({
    queryKey: ["state-dispensaries-list", state],
    queryFn: getDispensaries,
    enabled: !!state,
  });

  useEffect(() => {
    dispatch(loadingSet(statesLoading || stateDispensariesLoading));
  }, [statesLoading, stateDispensariesLoading]);

  const dispensaryOptions = stateDispensaries?.map((disp) => ({
    label: disp.name,
    value: disp.id,
  }));
  const stateOptions = states.map((state) => ({
    label: state.state_name,
    value: state.id,
  }));
  const handleCloseAndClearState = () => {
    handleClose();
    setState("");
    setDispensaries([]);
    setSelectOption("");
  };
  const selectOptions = [
    { label: "All", value: "all" },
    { label: "Select by dispensary ids", value: "selected" },
  ];
  useEffect(() => {
    if (selectOption.value === "all") {
      setDispensaries(dispensaryOptions);
    } else {
      setDispensaries([]);
    }
  }, [selectOption.value]);

  return (
    <>
      <Modal show={show} onHide={handleCloseAndClearState}>
        <ModalHeader closeButton>
          <Modal.Title>Create Email Instance</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6} className="mb-3">
              <FormGroup id="Name">
                <FormLabel className="fontweigh-500">State</FormLabel>
                <Select
                  name="state"
                  placeholder="Select State"
                  value={state}
                  onChange={(e) => setState(e)}
                  options={stateOptions}
                />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Col>
            <Col md={6} className="mb-3">
              <FormGroup id="Name">
                <FormLabel className="fontweigh-500">Select Options</FormLabel>
                <Select
                  name="select options"
                  placeholder="Select Option"
                  value={selectOption}
                  onChange={(e) => setSelectOption(e)}
                  options={selectOptions}
                />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Col>
            {selectOption.value === "selected" && (
              <Col className="mb-3">
                <FormGroup id="Name">
                  <FormLabel className="fontweigh-500">Dispensaries</FormLabel>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    name="dispensaries"
                    placeholder="Select Dispensaries"
                    value={dispensaries}
                    onChange={(e) => setDispensaries(e)}
                    options={dispensaryOptions}
                  />
                  <p className="text-danger">{error}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            className="fontweigh-500"
            onClick={handleCloseAndClearState}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            className="green-btn fontweigh-500"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send"} Email
          </Button>
        </ModalFooter>
      </Modal>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default AddEmailInstance;
