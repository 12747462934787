import { Button, Card, CardBody, Col, Image, Row } from "react-bootstrap";
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import verifiedBadge from "../assets/img/verifiedBadge.webp";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../redux/loadingSlice";
import "./DispensaryCard.css";

const DispensaryCard = ({
  dispensary,
  refetchDisp,
  setShowToast,
  setToastBody,
  setSuccess,
}) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.login.userData);

  if (!dispensary) {
    return;
  }
  const today = moment().format("dddd").toLowerCase();
  const workHours = dispensary?.workhours && dispensary.workhours[today];
  let workTimings;
  let openTimingsText;
  if (workHours?.includes("-")) {
    workTimings = workHours.split("-")[1];
    openTimingsText = (
      <div className="mx-2">
        <span className="green-status "></span>{" "}
        <p className="fs-8 m-0 d-inline fw-bold text-muted">
          Open until {today} at {moment(workTimings, "HH:mm").format("hh:mm A")}
        </p>
      </div>
    );
  } else {
    workTimings = workHours?.replaceAll("_", " ");
    openTimingsText = workTimings ? (
      <div className="mx-2">
        <span
          className={`${
            workTimings.includes("open")
              ? "green-status"
              : workTimings.includes("closed")
              ? "red-status"
              : "brown-status"
          }`}
        ></span>{" "}
        <p className="fs-8 m-0 d-inline text-muted">
          {workTimings} for {today}
        </p>
      </div>
    ) : (
      ""
    );
  }
  const handleToggleFav = async (id, is_favorite) => {
    dispatch(loadingSet(true));
    try {
      let res;
      if (is_favorite) {
        res = await axios({
          method: "DELETE",
          url: `/remove/wishlist/dispensary/${id}`,
        });
      } else {
        res = await axios({
          method: "POST",
          url: `/add/wishlist/dispensary`,
          data: { dispensary_id: id },
        });
      }
      refetchDisp();

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  return (
    <Card key={dispensary.id} className="image-card">
      <Link
        to={`${Routes.dispensaryDetails.path}/${
          dispensary.id
        }/${dispensary.name.replaceAll(" ", "-")}/profile`}
        className="text-decoration-none"
      >
        <CardBody
          className="disp-image rounded-top p-0"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${dispensary.cover_image})`,
          }}
        >
          <Row className=" mx-1">
            <Col xs={8}>
              <p
                className="bg-dark text-white px-1"
                style={{ width: "fit-content" }}
              >
                {dispensary.is_medical ? "MED" : ""}{" "}
                {dispensary.is_medical && dispensary.is_recreational ? "&" : ""}{" "}
                {dispensary.is_recreational ? "REC" : ""}
              </p>
            </Col>
          </Row>
          <Row className="content-left d-flex">
            <Col xs={3}>
              {dispensary?.logo && (
                <Image
                  src={dispensary.logo}
                  alt="Logo"
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "80%",
                    // height: "100%",
                    // width: "100%",
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
        <Col className="p-0 mt-2 rounded-bottom text-dark fontweigh-500 fs-7">
          <div className=" mx-2 mb-0 d-flex align-items-center">
            {dispensary.name}{" "}
            {dispensary.plan_id > 1 && (
              <Image
                alt="verified badge"
                // className="mb-4"
                src={verifiedBadge}
                width={35}
                height={30}
              />
            )}{" "}
          </div>

          {openTimingsText}
          <p className="mx-2 fs-8">
            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
            {dispensary?.distance?.toFixed(2)} miles away
          </p>
        </Col>
      </Link>
      {role === "user" && (
        <Button
          variant="link"
          onClick={() =>
            handleToggleFav(dispensary.id, dispensary.favourite_dispensary)
          }
          className="fav-btn"
        >
          {" "}
          <FontAwesomeIcon
            icon={faHeart}
            color={dispensary.favourite_dispensary ? "#ff9f89db" : "#fff"}
            className="fs-6"
          />
        </Button>
      )}
    </Card>
  );
};
export default DispensaryCard;
