import { useEffect, useState } from "react";
import axios from "../../../axios";
import { Col, Container, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";
import DispensaryCard from "../../../components/DispensaryCard";
import CustomPagination from "../../../components/Pagination";
import CustomToast from "../../../reusable/CustomToast";

const MyFavorites = () => {
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  // Functions
  const getMyFavorites = async ({ queryKey }) => {
    if (queryKey) {
      const [, page] = queryKey;
      const res = await axios(`/get/wishlist/dispensary?page=${page}&limit=20`);
      setTotalPages(res.data.data.totalPages);
      return res.data.data.dispensaries;
    }
  };

  const {
    data: favoriteDisps = [],
    isLoading: favoritesLoading,
    error: favoritesError,
    refetch: refetchFavoriteDisps,
  } = useQuery({
    queryKey: ["favorites-list", currentPage],
    queryFn: getMyFavorites,
  });
  useEffect(() => {
    dispatch(loadingSet(favoritesLoading));
  }, [favoritesLoading]);

  if (favoritesError) {
    return <ServerError errorMessage={favoritesError.response.data.message} />;
  }
  return (
    <Container fluid>
      <h3 className="green-color mt-4">My Favoirtes</h3>
      {favoritesLoading ? (
        <LoadingPlaceHolder />
      ) : favoriteDisps?.length ? (
        <Row>
          {favoriteDisps.map((disp) => (
            <Col lg={3} md={4} sm={6} key={disp.id}>
              <DispensaryCard
                dispensary={disp}
                refetchDisp={refetchFavoriteDisps}
                setShowToast={setShowToast}
                setToastBody={setToastBody}
                setSuccess={setSuccess}
              />
            </Col>
          ))}
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      ) : (
        <p className="text-center">No Favorites</p>
      )}
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </Container>
  );
};
export default MyFavorites;
