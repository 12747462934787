import { useEffect, useState } from "react";
import axios from "../../../axios";
import { Container } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";
import ReviewsTable from "../../../components/Table/ReviewsTable";

const MyReviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  // Functions
  const getReviews = async ({ queryKey }) => {
    if (queryKey) {
      const [, page] = queryKey;
      const res = await axios(`/dis/reviews?page=${page}&limit=20`);
      return res.data.data;
    }
  };

  const {
    data,
    isLoading: reviewsLoading,
    error: reviewsError,
  } = useQuery({
    queryKey: ["reviews-list", currentPage],
    queryFn: getReviews,
  });
  useEffect(() => {
    dispatch(loadingSet(reviewsLoading));
  }, [reviewsLoading]);
  if (reviewsError) {
    return <ServerError errorMessage={reviewsError.response.data.message} />;
  }
  return (
    <Container fluid>
      <h3 className="green-color mt-4">My Reviews</h3>
      {reviewsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <ReviewsTable
          reviews={data?.dispansary_reviews}
          totalPages={data?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Container>
  );
};
export default MyReviews;
