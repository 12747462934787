import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faHeart,
  faList,
  faLock,
  faMapMarkerAlt,
  faPen,
  faShoppingCart,
  faSignOutAlt,
  faUser,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Nav,
  Image,
  Navbar,
  Dropdown,
  Container,
  Button,
  Col,
  Row,
  Badge,
} from "react-bootstrap";
import axios from "../axios";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/img/logo.png";
import logoIcon from "../assets/img/wtmIcon.png";
import "./Navbar.css";
import useCart from "../Hooks/UseCart";
import AsyncSearchBar from "./AsyncSearchBar/AsyncSearchBar";
import { locationSet } from "../redux/LogIn/loginSlice";
import Notifications from "./Notifications";

const MainNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const [position, setPosition] = useState();

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isAdmin = pathname.includes("panel");
  const userData = useSelector((state) => state.login.userData);
  const userLocation = useSelector((state) => state.login.location);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const loading = useSelector((state) => state.loading.loading);

  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userData");
    axios.defaults.headers.common = {};
    history.replace(Routes.Signin.path);
    history.go(0);
  };
  useEffect(() => {
    if (position) {
      const stateName = position.find(
        (pos) => pos.types[0] === "administrative_area_level_1"
      );

      dispatch(
        locationSet({
          ...userLocation,
          state: stateName,
        })
      );
    }
    //   dispatch(
    //     userDataSet({
    //       ...user,
    //       state: user.state || stateName,
    //     })
    //   );
    // }
  }, [position]);

  async function getLocationInfo() {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation.lat},${userLocation.long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        setPosition(
          data.results[0].address_components.filter(
            (address) =>
              address.types[0] === "administrative_area_level_3" ||
              address.types[0] === "administrative_area_level_1"
          )
        );
      } else {
        console.log("Reverse geolocation request failed.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (userLocation.lat || userLocation.long) {
      getLocationInfo();
    }
  }, [userLocation.lat]);

  const { cart } = useCart();
  return (
    <>
      <Navbar
        // collapseOnSelect
        expand="lg"
        expanded={expanded}
        className="bg-body-tertiary d-block"
        sticky="top"
        // variant="light"
        // bg="light"
        backdrop="static"
        style={{
          boxShadow: "0px 0px 7px #65ac4b",
          zIndex: "1",
          paddingTop: loading && "0",
          background: "#fff",
        }}
      >
        {" "}
        {/* <Progress now={0} variant="success" size="xl" loading={loading} /> */}
        <Container fluid className="align-items-start justify-content-end">
          {!isAdmin && (
            <>
              <Col xs={6} lg={2} className="ml-2 d-flex align-items-start">
                <Navbar.Toggle
                  onClick={() => setExpanded((prevState) => !prevState)}
                  // aria-controls="responsive-navbar-nav"
                  className="me-4"
                />
                <Navbar.Brand className="me-4" as={Link} to={Routes.Home.path}>
                  <Image
                    style={{ height: "3.3rem" }}
                    fluid
                    src={isSmallScreen ? logoIcon : logo}
                    alt="logo"
                  />
                </Navbar.Brand>
              </Col>
              <Col lg={6}>
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="flex-column align-items-stretch"
                >
                  <>
                    <Row className="d-flex">
                      <Col lg={4} xl={3}>
                        <Row className="d-flex">
                          {/* <Col> */}

                          {userData.plan_id < 5 && (
                            <Nav.Link
                              style={{ fontSize: "0.9rem" }}
                              className="link-text fontweigh-500 "
                              as={Link}
                              to={{
                                pathname: userData.plan_id
                                  ? Routes.buyPlan.path
                                  : userData.role === "partner"
                                  ? userData.state
                                    ? Routes.buyPlan.path
                                    : Routes.MyProfile.path
                                  : Routes.Signup.path,
                                state: { type: "partner" },
                              }}
                              onClick={() => setExpanded(false)}
                            >
                              {userData.plan_id
                                ? "Upgrade Business"
                                : "List Business"}
                            </Nav.Link>
                          )}

                          {/* </Col> */}
                          <Col className="text-end">
                            {expanded && (
                              <Button
                                variant="link"
                                onClick={() => setExpanded(false)}
                              >
                                <FontAwesomeIcon
                                  className="fs-2 green-color"
                                  icon={faWindowClose}
                                />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        {userLocation.state && (
                          <AsyncSearchBar
                            lat={userLocation.lat}
                            long={userLocation.long}
                            state={userLocation.state.long_name}
                            setExpanded={setExpanded}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Nav>
                        <NavLink
                          activeClassName="underline"
                          className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                          to={{
                            pathname: `${Routes.Explore.path}/Storefronts`,
                            // state: {
                            //   id: 2,
                            // },
                          }}
                          onClick={() => setExpanded(false)}
                        >
                          Dispensaries
                        </NavLink>
                        <NavLink
                          activeClassName="underline"
                          className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                          to={{
                            pathname: `${Routes.Explore.path}/Deliveries`,
                            // state: {
                            //   id: 1,
                            // },
                          }}
                          onClick={() => setExpanded(false)}
                        >
                          Delivery
                        </NavLink>
                        <NavLink
                          activeClassName="underline"
                          className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                          to={{
                            pathname: Routes.NearbyDeals.path,
                            state: {
                              position,
                            },
                          }}
                          onClick={() => setExpanded(false)}
                        >
                          Deals
                        </NavLink>
                        <NavLink
                          className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                          activeClassName="underline"
                          to={Routes.allBrands.path}
                          onClick={() => setExpanded(false)}
                        >
                          Brands
                        </NavLink>
                        {/* <NavLink
                          className="fontweigh-700 link-text mt-4 mx-3 mb-1"
                          activeClassName="underline"
                          to={Routes.AllProducts.path}
                          onClick={() => setExpanded(false)}
                        >
                          Products
                        </NavLink> */}
                        <NavLink
                          activeClassName="underline"
                          className="fontweigh-500 link-text mt-4 mx-3 mb-1"
                          to={{
                            pathname: Routes.blogs.path,
                            // state: { name: "Blogs", type: "posts" },
                          }}
                          onClick={() => setExpanded(false)}
                        >
                          Blogs
                        </NavLink>
                      </Nav>
                    </Row>
                  </>
                </Navbar.Collapse>
              </Col>
            </>
          )}
          <Col
            md={4}
            xs={6}
            className="d-flex justify-content-end align-items-center float-end"
          >
            <div className="black-color fs-6 mx-md-2 mx-xl-3">
              <FontAwesomeIcon
                className="fs-4 location-icon"
                icon={faMapMarkerAlt}
              />{" "}
              {position && (
                <span className="responsive-text">
                  {position[0].short_name}
                </span>
              )}
            </div>
            {userData.role ? (
              // <Nav className="align-items-center">
              <>
                {!isAdmin && (
                  <Nav.Link
                    as={Link}
                    to={Routes.cart.path}
                    className="d-flex align-items-center px-1 icon-cover"
                  >
                    <FontAwesomeIcon
                      className="fontweigh-500 link-text cart-icon"
                      icon={faShoppingCart}
                      // style={{ height: "1.2em", width: "1.2em" }}
                    />
                    <Badge className="bg-green count-badge">
                      {cart?.cart_items && cart?.cart_items.length}
                    </Badge>
                  </Nav.Link>
                )}
                {userData.role !== "user" && <Notifications />}
                <Dropdown variant="link" className="px-1">
                  <Dropdown.Toggle variant="link" className="pt-1 px-0">
                    <div className="media d-flex align-items-center black-color">
                      {userData.profile_pic ? (
                        <Image
                          alt="profile pic"
                          src={userData.profile_pic}
                          className="user-avatar md-avatar rounded-circle"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faUser}
                          className="user-avatar md-avatar rounded-circle bg-dark"
                        />
                      )}
                      <div className="media-body align-items-center d-none d-lg-block ms-lg-2">
                        <span className="mb-0 font-small fontweigh-500 responsive-text">
                          {userData.name}
                        </span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    {(userData.role_id === 1 ||
                      (userData.role_id === 2 && userData.plan_id > 0)) && (
                      <Dropdown.Item
                        as={Link}
                        to={Routes.AdminDashboard.path}
                        // className="fw-bold"
                      >
                        <FontAwesomeIcon icon={faArrowRight} className="me-2" />{" "}
                        Dashboard
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      as={Link}
                      to={Routes.MyProfile.path}
                      // className="fw-bold"
                    >
                      <FontAwesomeIcon icon={faUserCircle} className="me-2" />{" "}
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={Routes.myOrders.path}
                      // className="fw-bold"
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" /> My
                      Orders
                    </Dropdown.Item>
                    {userData.role === "user" && (
                      <Dropdown.Item as={Link} to={Routes.myFavorites.path}>
                        <FontAwesomeIcon icon={faHeart} className="me-2" /> My
                        Favorites
                      </Dropdown.Item>
                    )}
                    {userData.role === "user" && (
                      <Dropdown.Item as={Link} to={Routes.myReviews.path}>
                        <FontAwesomeIcon icon={faPen} className="me-2" /> My
                        Reviews
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      // className="fw-bold"
                      onClick={() => history.push(Routes.ChangePassword.path)}
                    >
                      <FontAwesomeIcon icon={faLock} className="me-2" /> Change
                      Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-danger me-2"
                      />{" "}
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* </Nav> */}
              </>
            ) : (
              <Nav.Link
                className="fontweigh-500 green-color"
                href={Routes.Signin.path}
              >
                Sign In
              </Nav.Link>
            )}
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

export default MainNavbar;
